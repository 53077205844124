<template>
    <layout-default>
        <section class="services p-5">
            <div class="container mb-5 text-center">
                <h2 class="text-uppercase lined lined-center">Massage für den <span class="text-primary">Rücken</span></h2>
                <div class="row">
                    <div class="col-12" align="center">
                        <div class="videoWrapper">
                            <div id="player1">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12" align="center">
                        <button class="btn btn-primary" @click="enterFullscreen('player1')">Video in Vollbild anzeigen</button>
                    </div>
                </div>
            </div>
            <div class="container text-center">
                <h2 class="text-uppercase lined lined-center">Massage für den <span class="text-primary">Nacken</span></h2>
                <div class="row">
                    <div class="col-12" align="center">
                        <div class="videoWrapper">
                            <div id="player2">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12" align="center">
                        <button class="btn btn-primary" @click="enterFullscreen('player2')">Video in Vollbild anzeigen</button>
                    </div>
                </div>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';

export default {
    name: 'QRLanding',
    data() {
        return {
            isFullscreen: false
        };
    },
    components: {
        LayoutDefault
    },
    mounted() {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        /* eslint-disable */
        let player1;
        let player2;

        const checkYT = () => {
            if (typeof YT !== 'undefined') {
                player1 = new YT.Player('player1', {
                    videoId: 'kuzV88u1HOU',
                    width: '100%',
                    playerVars: {
                        controls: 1,
                        showinfo: 1
                    },
                    events: {
                        onStateChange: this.onPlayerStateChange
                    }
                });
                player1 = new YT.Player('player2', {
                    videoId: 'p8GqQuNvP2Q',
                    width: '100%',
                    playerVars: {
                        controls: 1,
                        showinfo: 0
                    },
                    events: {
                        onStateChange: this.onPlayerStateChange
                    }
                });
            } else {
                setTimeout(checkYT, 500);
            }
        };

        checkYT();
    },
    methods: {
        enterFullscreen(player) {
            const playerElement = document.getElementById(player);
            if (playerElement.requestFullscreen) {
                playerElement.requestFullscreen();
            } else if (playerElement.webkitRequestFullscreen) {
                playerElement.webkitRequestFullscreen();
            } else if (playerElement.mozRequestFullScreen) {
                playerElement.mozRequestFullScreen();
            } else if (playerElement.msRequestFullscreen) {
                playerElement.msRequestFullscreen();
            }
            this.isFullscreen = true;
        },
        onPlayerStateChange(event) {
            if (event.data === YT.PlayerState.ENDED && this.isFullscreen) {
                document.exitFullscreen();
                this.isFullscreen = false;
            }
        }
        
        /* eslint-enable */
    }
};
</script>

<style scoped>
/* Custom CSS for video player */
</style>
